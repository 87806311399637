import React, { Fragment } from "react";
import { ThemeProvider } from "styled-components";
import Layout from "../containers/Home/Layout";
import { interiorTheme } from "common/theme/interior";
// import LayoutInner from "common/components/Layout";
// import Titlebar from "common/components/Titlebar";
// import FAQToggle from "common/components/FAQs";
import "common/assets/css/main-page.css";
import Seo from "components/seo";
import TitleBar2 from "common/components/Titlebar2";
import SingleServicePage from "../containers/Home/pages.style";
import ServiceSectionTwo from "../containers/Sections/ServiceSectionTwo";
import ServiceSectionThree from "../containers/Sections/ServiceSectionThree";
import ServiceSectionOne from "../containers/Sections/ServiceSectionOne";
import ServiceSectionFour from "../containers/Sections/ServiceSectionFour";

const NonSurgicalHairReplacementforMen = () => {

  
  const seoData = {
    title: 'Non Surgical Hair Replacement for Men In Delhi - Radiance',
    description: 'Explore the best non surgical hair replacement for men in Delhi. Radiance Hair Studio offers natural-looking solutions to hair loss. Book your consultation now.',
    keywords: ['non surgical hair replacement for men in Delhi'],
  };

  // const faqs = [
  //   {
  //     title: "Is the process of replacement going to damage my natural hair?",
  //     description:
  //       "The extensions are attached on your head with the help of glue, tapes or clips. In each case, the adhesives are applied on your scalp, which has no connection with your natural hair-roots. So, there can remain no chance of hair damage for you.",
  //   },
  //   {
  //     title: "Is the process of replacement pain giving?",
  //     description:
  //       "The process that we apply at Radiance is a pure Non-Surgical hair Replacement in Delhi. Hence, there is nothing to do with pain. The full process we follow is an externally applied one.        ",
  //   },
  //   {
  //     title: "What to do, if I want to change my hairstyle frequently?",
  //     description:
  //       "We at Radiance will give you lots of options. You can go for bonding, where you can change your styles just by coloring your hair on regular basis. If you choose a general extension, then also you can change your appearance quite frequently, but you will have to visit us at Radiance each time for that.",
  //   },
  //   {
  //     title: "After getting an extension, how can I maintain my natural hair?",
  //     description:
  //       "First of all your natural hair will be trimmed. Secondly, you can go for clip method of hair extensions in Delhi. There you can open and re-wear the cap any time. Finally, in all other types of extensions, Radiance provides you with natural human hair as an extension. So, you can apply shampoos, serums, and anything for maintenance.",
  //   },
  // ];

  const sectionS1 = [
    {
      title: "Hair Patch for Men",
      img: "/hair-patch-for-men.png",
    },
    {
      title: "Human Hair Extension",
      img: "/7.png",
    },
    {
      title: "Hair Weaving",
      img: "/8.png",
    },
    {
      title: "Non-Surgical Hair Replacement",
      img: "/9.png  ",
    }
  ];

  const sectionS2 = [
    {
      img: "/service-images/Non-Surgical-Hair-Replacement-for-Men's.jpg",
      content: (
        <div>
          <h2>Non-Surgical Hair Replacement for Men's</h2>
          <p>
          Fashion is the underlined statement in the city life today. To be synchronized with it is the call of the hour for you now. Then how can you go ahead with baldness? Even if you are not suffering from baldness, you are still in need of a style signature for yourself. All the things you need can be availed while you go for the hair extensions in Delhi.
          </p>
          <h2>Give a new start with Radiance</h2>
          <p>
          We at Radiance Hair Studio take care of your hair and hairstyle quite professionally and of course ensure that your purpose is met perfectly. All types of Non-Surgical hair Replacement in Delhi are available with us and above everything, you will be given all sorts of options including male wigs, <a href="/hair-extensions-for-men.php" style={{ color: "#373737"}}>extensions</a> with different colors and styles. So, reach us today and show all your stylish approach.
          </p>
          <p>
          While you go for a hair extension, the primary thing that you must be feeling <a href="/about.php" style={{ color: "#373737"}}>about</a> is the process that will be followed. Whether the process is painful or not, whether you are going to get the aid or not – these few things cropped up in your mind. Get through the benefits and answers to some questions, going on at your mind. That will be helping you select the new look in a better format.
          </p>
          <p>
          At Radiance, we take care of all you need. Our hair styling plans are all externally applied and hence you will never feel any strain. Moreover, our professionals are always eager to guide you, so that your fashion-look remains updated among your friends and followers.
          </p>
        </div>
      ),
    },
  ];
  
  const sectionS3 = [
    {
      smallImg: "/non-surgical-hair-replacement-for-men1.png",
      largeImg: "/non-surgical-hair-replacement-for-men2.png",
      content: (
        <div>
          <h2>Benefits of going for Non-Surgical Hair Extensions</h2>
  
          <ul>
            <li>
            The style is surely the need of yours, which you are feeling shy to apply on your natural hairs. Get the male wigs or hair extensions and ensure that your style is latest. If you are suffering from baldness or semi-permanent hair loss, then also there is nothing to fear. Just apply the extensions or wigs and enjoy the grand and smart look in few minutes.
            </li>
            <li>
            Hair extensions are all to be applied externally. Hence there remains no chance of the type of side-effects. Glues and tapes are applied on your scalp, but those are of international quality. Hence, there is nothing to be afraid of the adhesives used in the process.
            </li>
            <li>
            Your natural hair will always remain intact and harmless. The extensions are applied from the scalp and adhesives or tapes are applied on the scalp itself and hence your natural hair roots remain completely away from all the chemicals. So, no chance of any hair damage, by any means.
            </li>
            <li>
            You can apply shampoos, conditioners and even serums on the extensions. In the case of clip-on, you can even open those at times and wear them, when you need. So, it’s completely hassle-free, in terms of use too.
            </li>
          </ul>
        </div>
      ),
    },
  ];
  
  const sectionS4 = [
    {
      title: "Questions often asked by our clients: FAQs",
      description:"",
      description2: "",
      faq: [
        {
          title: "Is the process of replacement going to damage my natural hair?",
          description:
            "The extensions are attached on your head with the help of glue, tapes or clips. In each case, the adhesives are applied on your scalp, which has no connection with your natural hair-roots. So, there can remain no chance of hair damage for you.",
        },
        {
          title: "Is the process of replacement pain giving?",
          description:
            "The process that we apply at Radiance is a pure Non-Surgical hair Replacement in Delhi. Hence, there is nothing to do with pain. The full process we follow is an externally applied one.",
        },
        {
          title: "What to do, if I want to change my hairstyle frequently?",
          description:
            "We at Radiance will give you lots of options. You can go for bonding, where you can change your styles just by coloring your hair on regular basis. If you choose a general extension, then also you can change your appearance quite frequently, but you will have to visit us at Radiance each time for that.",
        },
        {
          title: "After getting an extension, how can I maintain my natural hair?",
          description:
            "First of all your natural hair will be trimmed. Secondly, you can go for clip method of hair extensions in Delhi. There you can open and re-wear the cap any time. Finally, in all other types of extensions, Radiance provides you with natural human hair as an extension. So, you can apply shampoos, serums, and anything for maintenance.",
        }
      ],
    },
  ];
  

  return (
    <ThemeProvider theme={interiorTheme}>
      <Fragment>
        <Seo {...seoData} />
        <Layout>
          <TitleBar2 title="Non-Surgical Hair Replacement for Men's" banner="/non-surgical-hair-replacement-for-men-banner.png"/>
          <SingleServicePage>
            <ServiceSectionOne data={sectionS1} title="Non-Surgical Hair Replacement for Men's" />
            <ServiceSectionTwo data={sectionS2} />
            <ServiceSectionThree data={sectionS3} />
            <ServiceSectionFour data={sectionS4} />
          </SingleServicePage>
        </Layout>
      </Fragment>
    </ThemeProvider>
  );
};
export default NonSurgicalHairReplacementforMen;
